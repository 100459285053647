/* Utils Start */

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.border {
  border: 1px solid black;
}

.border-t {
  border-top: 1px solid black;
}

.border-r {
  border-right: 1px solid black;
}

.border-b {
  border-bottom: 1px solid black;
}

.bg-white {
  background-color: white;
}

.bg-transparent {
  background-color: transparent;
}

.bg-gray {
  background-color: rgb(230, 230, 230) !important;
}

.ml-16 {
  margin-left: 16px;
}

.pointer {
  cursor: pointer;
}

/* Utils End */

.jodit-add-new-line {
  display: none !important;
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  display: flex;
  margin: 10px 0;
  padding: 10px;
  justify-content: space-between;
}

.badge {
  border: 1px solid #1ab394;
  background: #1ab394;
  color: white;
  border-radius: 4px;
  padding: 2px 8px;
}

.badge-outline {
  background-color: transparent;
  color: black;
}

.mather {
  background: red;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.buttonBlock {
  position: absolute;
  bottom: 0;
  padding: 30px 0 30px 300px;
  width: 100%;
  left: 0;
  z-index: 10;
  background: rgb(244, 246, 248);
  box-shadow: 0 -8px 8px 0 rgba(127, 131, 133, 0.5);
  border-top: 2px solid #1976d2;
}

.contentContainer {
  padding-bottom: 60px;
}
.form {
  display: flex;
  flex-direction: column;
  margin: 20px 50px;
}
.form .form-field {
  margin-bottom: 20px;
}
.form .form-field.margin {
  margin-top: 20px;
}
.form .form-select {
  margin-bottom: 20px;
}
.form .form-button {
  width: 200px;
}
.section-title {
  font-size: 20px;
  margin: 10px 0 30px 0;
}
.list-container {
  flex: 1;
}
.list-page-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 100%;
}
.list-page-search-block {
  display: flex;
  align-items: center;
  gap: 20px;
}
.draggable-list-item-copy {
  cursor: copy;
  display: block;
}
.search-item {
  background-color: #1ab394;
  color: #ffffff;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  font-size: 15px;
  margin: 2px 4px 2px 0;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  height: fit-content;
}
.modal-list {
  display: flex;
  flex-wrap: wrap;
}
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 400px;
  background-color: white;
  padding: 32px;
  overflow-y: auto;
}
.list-item-block {
  background-color: #1ab394;
  color: #ffffff;
  padding: 8px;
  display: flex;
  width: fit-content;
  align-items: center;
  border-radius: 3px;
  font-size: 18px;
  margin: 5px 0;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.list-item-block svg {
  cursor: pointer;
}
.sort-input {
  background: white;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  padding: 10px 5px;
  width: 50px;
  text-align: center;
  outline: none;
}
.list-mapping-item {
  border: 1px solid #1ab394;
  color: #1ab394;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
}
.list-mapping-title {
  font-size: 20px;
}
.list-mapping-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.mapping-container {
  padding: 50px;
}
.switchWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}
